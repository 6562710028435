import RWSClient, { RWSContainer } from '@rws-framework/client';
import initComponents from './application/_initComponents';
import './styles/main.scss';

interface Process {
    env: NodeJS.ProcessEnv;
}


declare var process: Process;

async function initializeApp() {
    const theClient = RWSContainer().get(RWSClient);
    
    theClient.onInit(async () => {
        initComponents(theClient.appConfig.get('parted'));
    });    
    


    await theClient.start({
        pubUrlFilePrefix: '/',
    });

    theClient.appConfig.set('recaptcha_site_key', process.env.RECAPTCHA_SITE_KEY);
    theClient.appConfig.set('app_url', process.env.APP_URL);


    theClient.assignClientToBrowser();
}

initializeApp().catch(console.error);
