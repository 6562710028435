import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="homepage">
    <div class="logo-container">
        <div class="logo-svg${ x => x.go ? ' go' : '' }">
            ${x => x.showAsset('assets/svg/logo.svg')}
        </div>
        ${T.repeat((x, c) => x.suluMenu, T.html`<div class="frame ${item => item.frame_class}">                     
            <a @click="${ (item, c) => c.parent.clickMenu(item, c.event) }" href="${(item, c) => item.url}">${(item, c) => item.title}</a>
        </div>`, { positioning: true })}            
    </div>

    <the-separator class="showspace-sep"></the-separator>

    <div class="showspace">    
        ${T.when(x => x.webContent.gallery.length > 0, T.html`<div class="image">
            <img src="${x => x.webContent.gallery[0].thumbnails['small_front_img']}" />
        </div>`)}
        <p class="slogan${x => x.webContent.gallery.length > 0 ? ' with-image' : '' }">
            <strong>${x => x.webContent.slogan1}</strong>
            ${T.when(x => x.webContent.mini_slogan1, T.html`<span :innerHTML="${ x => x.webContent.mini_slogan1}"></span>`)}
        </p>    
    </div> 

    <the-separator></the-separator>
    
    <div class="showspace a-right">        
        <p class="slogan${x => x.webContent.gallery.length > 1 ? ' with-image' : '' }">
            <strong>${x => x.webContent.slogan2}</strong>
            ${T.when(x => x.webContent.mini_slogan2, T.html`<span :innerHTML="${ x => x.webContent.mini_slogan2}"></span>`)}            
        </p>    
        ${T.when(x => x.webContent.gallery.length > 1, T.html`<div class="image">
            <img src="${x => x.webContent.gallery[1].thumbnails['small_front_img']}" />
        </div>`)}
    </div>      
</div>
${T.when(x => x.webContent.gallery.length, T.html`<div id="home_carousel_wrapper">
    <splide-slider mode="carousel" :options="${ x => x.carouselOptions }" :images="${x => x.webContent.gallery.filter((el: any, index: any) => index > 1).map((suluImage: any) => ({ 
        small: suluImage.thumbnails.small_carousel, 
        big: suluImage.thumbnails.big_front_img
    }))}"></splide-slider>
</div>`)}  
`;

import './styles/layout.scss';
const styles = T.css`@keyframes spinIn {
  0% {
    transform: scale(0) rotate(0deg);
    opacity: 0;
  }
  10% {
    transform: scale(0.2) rotate(90deg);
  }
  25% {
    transform: scale(0.4) rotate(360deg);
  }
  50% {
    transform: scale(0.6) rotate(720deg);
  }
  75% {
    transform: scale(0.8) rotate(1440deg);
  }
  100% {
    transform: scale(1) rotate(2880deg);
    opacity: 1;
  }
}
.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000;
  text-align: center;
  font-family: "Roboto", sans-serif;
  padding: 0 0.5rem;
}
.homepage header {
  font-size: 3rem;
  color: var(--heading-color, #E9C476);
  margin: 2rem 0 1rem 0;
  padding: 0;
  font-weight: 400;
  font-family: "Bungee", sans-serif;
}
.homepage .logo-container {
  position: relative;
  display: inline-block;
  margin-top: 6rem;
  width: 35rem;
  height: 34rem;
}
@media (max-width: 768px) {
  .homepage .logo-container {
    width: 25rem;
    height: 24rem;
  }
}
@media (max-width: 480px) {
  .homepage .logo-container {
    width: 20rem;
    height: 19rem;
    margin-top: 4rem;
  }
}
.homepage .logo-container .logo-svg {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  transform: scale(0) rotate(0deg);
  opacity: 0;
}
.homepage .logo-container .logo-svg svg {
  max-width: 100%;
  max-height: 100%;
}
.homepage .logo-container .logo-svg.go {
  transform: scale(1) rotate(360deg);
  animation: spinIn 0.8s linear;
  opacity: 1;
}
.homepage .logo-container .frame {
  position: absolute;
  width: 18rem;
  height: 8rem;
  display: flex;
  justify-content: center;
  color: var(--heading-color, #E9C476);
  align-items: center;
}
@media (max-width: 768px) {
  .homepage .logo-container .frame {
    width: 10rem;
    height: 5rem;
  }
}
@media (max-width: 480px) {
  .homepage .logo-container .frame {
    width: 8rem;
    height: 4rem;
  }
}
.homepage .logo-container .frame .bg {
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
}
.homepage .logo-container .frame .bg svg {
  width: 100%;
}
.homepage .logo-container .frame .bg svg .cls-1, .homepage .logo-container .frame .bg svg .cls-5 {
  fill: var(--heading-color, #E9C476);
}
.homepage .logo-container .frame a {
  color: var(--heading-color, #E9C476);
  text-decoration: none;
  font-size: 2.5rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: normal;
  transform: translateY(-0.5rem);
  font-family: "Bungee", sans-serif;
  font-weight: 400;
}
@media (max-width: 768px) {
  .homepage .logo-container .frame a {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .homepage .logo-container .frame a {
    font-size: 1rem;
  }
}
.homepage .logo-container .frame-top-left,
.homepage .logo-container .frame-bottom-left {
  transform: scaleX(-1);
}
.homepage .logo-container .frame-top-left a,
.homepage .logo-container .frame-bottom-left a {
  transform: scaleX(-1) translateY(-0.5rem);
}
.homepage .logo-container .frame-top-left {
  top: -2rem;
  left: -14rem;
}
@media (max-width: 1024px) {
  .homepage .logo-container .frame-top-left {
    top: -5.5rem;
    left: -8rem;
  }
}
@media (max-width: 768px) {
  .homepage .logo-container .frame-top-left {
    left: -4rem;
  }
}
@media (max-width: 480px) {
  .homepage .logo-container .frame-top-left {
    top: -4.5rem;
    left: -1.1rem;
  }
}
.homepage .logo-container .frame-top-right {
  top: -2rem;
  right: -14rem;
}
@media (max-width: 1024px) {
  .homepage .logo-container .frame-top-right {
    top: -5.5rem;
    right: -8rem;
  }
}
@media (max-width: 768px) {
  .homepage .logo-container .frame-top-right {
    right: -4rem;
  }
}
@media (max-width: 480px) {
  .homepage .logo-container .frame-top-right {
    top: -4.5rem;
    right: -1.1rem;
  }
}
.homepage .logo-container .frame-top-left .bg, .homepage .logo-container .frame-top-right .bg {
  transform: rotate(180deg);
}
.homepage .logo-container .frame-top-left a, .homepage .logo-container .frame-top-right a {
  padding-top: 2rem;
}
.homepage .logo-container .frame-bottom-left {
  bottom: -2rem;
  left: -14rem;
}
@media (max-width: 1024px) {
  .homepage .logo-container .frame-bottom-left {
    bottom: -5.5rem;
    left: -8rem;
  }
}
@media (max-width: 768px) {
  .homepage .logo-container .frame-bottom-left {
    left: -4rem;
  }
}
@media (max-width: 480px) {
  .homepage .logo-container .frame-bottom-left {
    bottom: -4.5rem;
    left: -1.1rem;
  }
}
.homepage .logo-container .frame-bottom-right {
  bottom: -2rem;
  right: -14rem;
}
@media (max-width: 1024px) {
  .homepage .logo-container .frame-bottom-right {
    bottom: -5.5rem;
    right: -8rem;
  }
}
@media (max-width: 768px) {
  .homepage .logo-container .frame-bottom-right {
    right: -4rem;
  }
}
@media (max-width: 480px) {
  .homepage .logo-container .frame-bottom-right {
    bottom: -4.5rem;
    right: -1.1rem;
  }
}
.homepage .dots-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.homepage .dots-container .dot {
  width: 3rem;
  height: 3rem;
  margin: 0 1rem;
}
.homepage .dots-container .dot.small {
  width: 2rem;
  height: 2rem;
}
@media (max-width: 768px) {
  .homepage .dots-container .dot {
    width: 2.5rem;
    height: 2.5rem;
    margin: 0 0.75rem;
  }
  .homepage .dots-container .dot.small {
    width: 1.5rem;
    height: 1.5rem;
  }
}
@media (max-width: 480px) {
  .homepage .dots-container .dot {
    width: 2rem;
    height: 2rem;
    margin: 0 0.5rem;
  }
  .homepage .dots-container .dot.small {
    width: 1rem;
    height: 1rem;
  }
}

.showspace {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  width: 100%;
  padding: 2rem 0;
  margin: 0 auto;
  gap: 1rem;
}
.showspace .slogan {
  padding-left: 4rem;
}
.showspace .slogan strong {
  font-size: 3rem;
  color: var(--heading-color, #E9C476);
  margin: 2rem 0 1rem 0;
  padding: 0;
  font-weight: 400;
  font-family: "Bungee", sans-serif;
}
.showspace .slogan span {
  margin-top: 0.3rem;
  font-family: "Roboto", sans-serif;
}
.showspace .slogan.with-image {
  width: calc(100% - 434px);
}
.showspace .image {
  max-width: 350px;
  border-radius: 12px;
  border: 2px solid var(--heading-color, #E9C476);
}
.showspace .image img {
  border-radius: 10px;
  max-width: 100%;
  display: block;
}
.showspace.a-right {
  justify-content: right;
  align-items: center;
}
.showspace.a-right .slogan {
  padding-right: 4rem;
  padding-left: 0rem;
  margin: 0 auto;
}

.showspace-sep {
  margin-top: 8rem;
}

#home_carousel_wrapper {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .showspace {
    padding: 0;
    margin: 0;
    margin-bottom: 2rem;
    justify-content: center;
    align-items: center;
  }
  .showspace .slogan {
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
  }
  .showspace .slogan.with-image {
    width: 100%;
  }
  .showspace.a-right {
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  .showspace.a-right .slogan {
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
  }
  .showspace.a-right .slogan.with-image {
    width: 100%;
  }
}`;

const shadowOptions = {"mode":"open"};
import { RWSView, observable, RWSViewComponent, attr } from '@rws-framework/client';
import { IHomePage } from '../../types/pages/IHomePage';
import { MenuEntry } from '../../types/MenuEntry';
import { SplideOpts } from '../splide-slider/component';

@RWSView('homepage-page', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class HomePageComponent extends RWSViewComponent  {

    static fileList: string[] = [
        'assets/svg/logo.svg',
        'assets/svg/frame.svg',       
        'assets/svg/icon-frame.svg',
        'assets/svg/dot.svg',
    ];

    @observable webContent: IHomePage = {
        welcomeText: "",
        slogan1: "",
        image1: "",
        mini_slogan1: "",
        slogan2: "",
        image2: "",
        mini_slogan2: "",
        title: "",
        page_title: "",
        description: "",
        url: null
    };

    @attr menu: string;
    @observable suluMenu: MenuEntry[] = [];
    @observable go: boolean = false;
    @observable carouselOptions: SplideOpts = {
        type: 'loop',
        start: 0,
        perPage: 6,
        rewind: false,
        autoplay: true,
        interval: 3000,
        pauseOnHover: true,        
        speed: 800,
        easing: 'ease-in-out',
        breakpoints: {
            1400: {
                perPage: 5,
            },
            1200: {
                perPage: 4,
            },
            992: {
                perPage: 3,
            },
            768: {
                perPage: 2,
            },
            320: {
                perPage: 1,
            }
        },
    };

    connectedCallback(): void {
        super.connectedCallback();

        if (this.menu) {
            try {        
                const parsed: MenuEntry[] = JSON.parse(this.menu);                

                if(parsed.length){
                    parsed[0].frame_class = 'frame-top-left';
                }                

                if(parsed.length > 1){
                    parsed[1].frame_class = 'frame-top-right';
                }

                if(parsed.length > 2){
                    parsed[2].frame_class = 'frame-bottom-left';
                }

                if(parsed.length > 3){
                    parsed[3].frame_class = 'frame-bottom-right';
                }

                this.suluMenu = parsed;                                
            } catch (e) {
                console.error('Invalid homepage menu JSON:', e);
            }
        }
        this.go = true;
    }

    clickMenu(item: MenuEntry, event: Event)
    {
        event.preventDefault();

        this.$emit('page:url:change', item.url);
    }
}

HomePageComponent.defineComponent();

export { HomePageComponent };
