import { EntryPoint } from '../components/entrypoint/component';
import { RWSClientInstance } from '@rws-framework/client';
import { allComponents, provideFluentDesignSystem } from '@fluentui/web-components';
import { DefaultPageLayout } from '../components/default-page/component';
import { HomePageComponent } from '../components/homepage-page/component'; // Correct import path
import { ContactPageComponent } from '../components/contact-page/component';
import { ProductPageComponent } from '../components/product-page/component';
import { ProjectsPageComponent } from '../components/projects-page/component';
import { Header } from '../components/header/component';
import { SplideSlider } from '../components/splide-slider/component';
import { Separator } from '../components/the-separator/component';
import { ReCaptchaComponent } from '../components/recaptcha/component';

export default (parted: boolean) => {
    EntryPoint;
    SplideSlider;
    HomePageComponent;
    Separator;
    ReCaptchaComponent;

    if (!parted) {
        Header
        DefaultPageLayout;        
        ContactPageComponent;
        ProductPageComponent;
        ProjectsPageComponent;
    }

    provideFluentDesignSystem().register(allComponents);
    RWSClientInstance.defineAllComponents();
};
