import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`${T.when(x => x.webContent.gallery.length, T.html`<div id="main_slider_wrapper"><splide-slider :images="${x => x.webContent.gallery.map((suluImage: any) => ({ 
    small: suluImage.thumbnails.slider_img, 
    big: suluImage.thumbnails.big_front_img
 }))}"></splide-slider></div>`)}  
<article>
    <h1>${x => x.webContent.page_title}</h1>

    <div class="description" :innerHTML="${x => x.webContent.article}"></div>
</article>`;

import './styles/layout.scss';
const styles = T.css`article {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
}
article h1 {
  font-size: 3rem;
  color: var(--heading-color, #E9C476);
  margin: 2rem 0 1rem 0;
  padding: 0;
  font-weight: 400;
  font-family: "Bungee", sans-serif;
}`;

const shadowOptions = {"mode":"open"};
import { RWSViewComponent, RWSView, observable, attr } from '@rws-framework/client';
import { IDefaultPage } from '../../types/pages/IDefaultPage';
import { SplideImageEntry } from '../splide-slider/component';
import { IGalleryImage } from 'src/types/pages/IGalleryImage';

@RWSView('default-page', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class DefaultPageLayout extends RWSViewComponent  {  
    @observable webContent: IDefaultPage;
    @observable sliderImages: SplideImageEntry[] = [];

    @observable currentUrl: string = window.location.pathname;

    connectedCallback(): void {
        super.connectedCallback();
    }
}

DefaultPageLayout.defineComponent();

export { DefaultPageLayout };
