import { RWSService } from "@rws-framework/client";

class StylingService extends RWSService
{
    waitForTransition(element: HTMLElement): Promise<void> {
        return new Promise((resolve) => {
            const handleTransitionEnd = (event: TransitionEvent) => {
                if (event.target === element) {
                    element.removeEventListener('transitionend', handleTransitionEnd);
                    resolve();
                }
            };
            
            // Add an event listener for the transition end event
            element.addEventListener('transitionend', handleTransitionEnd);
        });
    }
}

export default StylingService.getSingleton();
export {
    StylingService as StylingServiceInstance
}