import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`${T.when(x => x.webContent, T.html`<div class="contact-page">
    <div class="left">
        <h1>${x => x.webContent.page_title}</h1>
        <div class="company-info">
            <p>${x => x.webContent.phone ? x.webContent.phone : 'No phone number available'}</p>
            <p>${x => x.webContent.email ? x.webContent.email : 'No email available'}</p>
            <p>${x => x.webContent.address ? x.webContent.address : 'No address available'}</p>
            <p>${x => x.webContent.nip ?  'Nip: ' + x.webContent.nip : ''}</p>
            <p>${x => x.webContent.regon ?  'Regon: ' + x.webContent.regon : ''}</p>
            <p>${x => x.webContent.bank_account ?  'Konto bankowe: ' + x.webContent.bank_account : ''}</p>
        </div>

        <div class="google-map">
            ${T.when(x => x.webContent.googleMap, T.html`<iframe src="${x => x.webContent.googleMap}"></iframe>`)}
        </div>
    </div>
    <div class="right">
        <h2>Skontaktuj się!</h2>
        ${T.when(x => !x.done, T.html`<form>
            <input type="text" name="phone" placeholder="telefon">
            <input type="email" name="email" placeholder="email">
            <div class=captcha-holder><re-captcha :callback="${x => x.captchaCallback}"></re-captcha></div>
            <div class="text_save">
                <textarea name="content" placeholder="Wiadomość"></textarea>
                <button type="submit">Wyślij</button>
            </div>
        </form>`)}
        ${T.when(x => x.done, T.html`
            <div class="form-success">
                <h2>Formularz został wysłany.</h2>
            </div>
        `)}
    </div>    
</div>`)}
`;

import './styles/layout.scss';
const styles = T.css`.contact-page {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 0 1rem;
}
.contact-page h1, .contact-page h2 {
  font-size: 3rem;
  color: var(--heading-color, #E9C476);
  margin: 2rem 0 1rem 0;
  padding: 0;
  font-weight: 400;
  font-family: "Bungee", sans-serif;
}
.contact-page .left, .contact-page .right {
  flex: 0 0 50%;
  max-width: 50%;
}
@media screen and (max-width: var(--rws-md-width, 1200px)) {
  .contact-page .left, .contact-page .right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-sm-width, 992px)) {
  .contact-page .left, .contact-page .right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-xs-width, 768px)) {
  .contact-page .left, .contact-page .right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .contact-page .left, .contact-page .right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 768px) and (max-width: var(--rws-md-width, 1200px)) {
  .contact-page .left, .contact-page .right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 768px) and (max-width: var(--rws-sm-width, 992px)) {
  .contact-page .left, .contact-page .right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 768px) and (max-width: var(--rws-xs-width, 768px)) {
  .contact-page .left, .contact-page .right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.contact-page form input, .contact-page form textarea {
  border-radius: 6px;
  padding: 0.6rem;
  font-size: 1.1rem;
  border: 2px solid var(--heading-color, #E9C476);
  box-shadow: none;
  margin-bottom: 1rem;
  display: block;
}
.contact-page form button {
  border: 0;
  background-color: var(--heading-color, #E9C476);
  padding: 0.6rem;
  color: var(--primary-color, #ffffff);
  border-radius: 6px;
}
.contact-page form .text_save {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.contact-page form .text_save textarea {
  margin-bottom: 0rem;
  margin-right: 1rem;
}
.contact-page .google-map {
  border-radius: 6px;
  border: 2px solid var(--heading-color, #E9C476);
  height: 250px;
  width: 95%;
}
.contact-page .google-map iframe {
  width: 100%;
  height: 250px;
  border: none;
  box-shadow: none;
}
.contact-page .form-success {
  border-radius: 6px;
  border: 2px solid var(--heading-color, #E9C476);
}
.contact-page .form-success h2 {
  font-size: 3rem;
  color: var(--heading-color, #E9C476);
  margin: 2rem 0 1rem 0;
  padding: 0;
  font-weight: 400;
  font-family: "Bungee", sans-serif;
  text-align: center;
  font-size: 1.5rem;
  color: #FFF;
  margin: 1rem;
}`;

const shadowOptions = {"mode":"open"};
import { RWSView, observable, RWSViewComponent, attr } from '@rws-framework/client';
import { IDefaultPage } from '../../types/pages/IDefaultPage';
import { ReCaptchaResponse, ReCaptchaSuccessResponse } from '../recaptcha/component';

interface IContactPage extends IDefaultPage {
    googleMap: string;
    phone: string;
    email: string;
    address: string;
    title: string;
}

type FormPayload = {
    phone: string,
    email: string,
    content: string,
    captcha_token: string | null
};

const FORM_ROUTE = ''; // Define your form submission route here


@RWSView('contact-page', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class ContactPageComponent extends RWSViewComponent  {
    @attr pageData: string;
    @observable webContent: IContactPage = null;
    @observable done: boolean = false;
    @observable captchaApproved: boolean = false;
    @observable captchaCallback: (this: ContactPageComponent, response: ReCaptchaResponse) => Promise<void> = this.doReCaptcha.bind(this);

    private captchaToken: string = null;
   
    private formPayload: FormPayload = {
        phone: '',
        email: '',
        content: '',
        captcha_token: null
    };

    connectedCallback(): void 
    {
        super.connectedCallback();        

        if (this.pageData) {
            this.webContent = JSON.parse(this.pageData);
            console.log('Content loaded:', this.webContent);
        }
    }

    private async doReCaptcha(captchaResponse: ReCaptchaResponse): Promise<void>
    {
        if(captchaResponse.code === 200){
            this.captchaApproved = true;
            this.captchaToken = (captchaResponse as ReCaptchaSuccessResponse).data.token;        
            
            if(!this.captchaApproved){
                console.log('no captcha!!!')
                return;
            }
            
            const form = this.$('form') as HTMLFormElement;
    
            this.formPayload = {            
                phone: (form.querySelector<HTMLInputElement>('[name="phone"]')).value,
                email: (form.querySelector<HTMLInputElement>('[name="email"]') ).value,
                content: (form.querySelector<HTMLInputElement>('[name="content"]')).value,
                captcha_token: this.captchaToken
            };        
            
            await this.apiService.post('/api/contact', this.formPayload);    
            form.reset();
    
            this.done = true;
        }else{
            alert('ReCaptcha error!');
        }        
    }
}

ContactPageComponent.defineComponent();

export { ContactPageComponent };
