import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="g-recaptcha" data-sitekey="${x => x.recaptchaKey}"></div>
`;

const styles: null = null;

import { RWSView, observable, RWSViewComponent } from '@rws-framework/client';

type GReCaptchaType = {
    enterprise: {
        ready: (callback: () => Promise<void> | null) => void,
        execute: (siteKey: string, options: any) => Promise<string>
    }
};

declare var grecaptcha: GReCaptchaType;

interface ReCaptchaResponseBase {
    code: number,
    data: unknown | null
}

export type ReCaptchaResponseData = {
    token: string
} 

export interface ReCaptchaSuccessResponse extends ReCaptchaResponseBase {
    data: ReCaptchaResponseData
}

export interface ReCaptchaErrorResponse extends ReCaptchaResponseBase {
    data: Error
}

export type ReCaptchaResponse = ReCaptchaSuccessResponse | ReCaptchaErrorResponse

@RWSView('re-captcha', null, { template: rwsTemplate, styles })
class ReCaptchaComponent extends RWSViewComponent  {
    @observable form: HTMLFormElement = null;
    @observable callback: (response: ReCaptchaResponse) => Promise<void> = async function (response: ReCaptchaResponse) { };

    recaptchaSDK: GReCaptchaType;
    recaptchaNode: HTMLElement;
    @observable recaptchaKey: string;

    async connectedCallback(): Promise<void> {
        super.connectedCallback();
        this.form = this.closest('form');
        this.recaptchaNode = this.$('.g-recaptcha') as HTMLElement;        
        this.recaptchaKey = this.config.get('recaptcha_site_key');
        
        if (typeof grecaptcha === 'undefined') {            
            await this.loadRecaptcha();
        }

        this.form.addEventListener('submit', async function(this: ReCaptchaComponent, e: SubmitEvent){            
            e.preventDefault();

            if(!this.recaptchaSDK){
                throw new Error('No ReCaptcha SDK!')
            }
                
            const verified = await new Promise<string | false>((resolve, reject) => {
                this.recaptchaSDK.enterprise.ready(async () => {
                    try {                        
                        const token = await grecaptcha.enterprise.execute(this.recaptchaKey, {action: 'LOGIN'});

                        try {
                            resolve(token);
                        }catch(callError: Error | unknown){
                            reject(callError);
                        }
                        
                    }catch(error: Error | unknown){                        
                        alert('Captcha error. Check console logs.')
                        console.error(error);
                        resolve(false);
                    }                    
                }); 
            });

            if(verified){
                await this.callback({ code: 200, data: { token: verified } });
            }
        }.bind(this))
    }

    private async loadRecaptcha(): Promise<void> {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = 'https://www.google.com/recaptcha/enterprise.js?render='+this.recaptchaKey;                
            script.onload = () => {
                this.recaptchaSDK = grecaptcha;
                resolve();
            };
            this.shadowRoot.appendChild(script);
        });        
    }
}

ReCaptchaComponent.defineComponent();

export { ReCaptchaComponent };
