import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="separator">
    <div class="dot">
        ${x => x.showAsset('assets/svg/dot.svg')}
    </div>    
    <div class="icon-frame">
        ${x => x.showAsset('assets/svg/icon-frame.svg')}
    </div>    
    <div class="dot">
        ${x => x.showAsset('assets/svg/dot.svg')}
    </div>    
</div>
`;

import './styles/layout.scss';
const styles = T.css`.separator {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  margin: 1.5rem auto;
}
.separator > div.dot svg {
  width: 2.3rem;
}
.separator > div.icon-frame {
  position: relative;
}
.separator > div.icon-frame::before {
  position: absolute;
  content: "";
  display: block;
  background-color: var(--heading-color, #E9C476);
  border-radius: 50%;
  width: 81%;
  height: 78%;
  left: 10%;
  top: 9%;
}
.separator > div.icon-frame svg {
  width: 6.5rem;
}
.separator > div.icon-frame svg .cls-1 {
  fill: #9d8a25;
}
.separator > div.icon-frame svg .cls-2 {
  fill: var(--heading-color, #E9C476);
}
@media (max-width: 768px) {
  .separator {
    gap: 1.5rem;
  }
  .separator > div.dot svg {
    width: 1.8rem;
  }
  .separator > div.icon-frame::before {
    width: 70%;
    height: 70%;
    left: 15%;
    top: 15%;
  }
  .separator > div.icon-frame svg {
    width: 5rem;
  }
}
@media (max-width: 480px) {
  .separator {
    gap: 1rem;
  }
  .separator > div.dot svg {
    width: 1.5rem;
  }
  .separator > div.icon-frame::before {
    width: 60%;
    height: 60%;
    left: 20%;
    top: 20%;
  }
  .separator > div.icon-frame svg {
    width: 4rem;
  }
}`;

const shadowOptions = {"mode":"open"};
import { RWSViewComponent, RWSView } from '@rws-framework/client';


@RWSView('the-separator', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class Separator extends RWSViewComponent  {
    static fileList: string[] = [
        'assets/svg/icon-frame.svg',
        'assets/svg/dot.svg',
    ];
}

Separator.defineComponent();

export { Separator };
