import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<header class="${ x => x.go && x.isOn ? '' : 'unloaded'}">
    <div class="navbar">
        <ul class="navbar__menu${x => x.active ? ' active' : ''}" id="navbarMenu">
            <li id="the_logo">
                <div class="logo-svg${ x => x.go ? ' go' : '' }">
                    ${x => x.showAsset('assets/svg/logo.svg')}
                </div>
            </li>
            <li>
                <a @click="${ (x, c) => x.goHome(c.event)}" href="/">Strona Główna</a>
            </li>
            ${T.repeat((x, c) => x.suluMenu, T.html`
                <li>
                    <a @click="${(item, c) =>  c.parent.clickMenu(item, c.event)}" href="${(item, c) => item.url}">${(item, c) => item.title}</a>
                </li>
            `, { positioning: true })}
        </ul>
        <div class="navbar__toggle${x => x.active ? ' active' : ''}" id="navbarToggle" @click="${x => x.menuToggle() }">
            <span></span>
            <span></span>
            <span></span>
        </div>
        <h4>Ceg-Bet</h4>
    </div>    
</header>
`;

import './styles/layout.scss';
const styles = T.css`header {
  background-color: var(--heading-color, #E9C476);
  margin-bottom: 0.2rem;
  height: 6.4rem;
  transition: all 0.4s ease-in-out;
}
header #the_logo {
  transition: opacity 1s ease-in-out;
}
header.unloaded {
  height: 0;
  opacity: 0;
  overflow: hidden;
}
header.unloaded #the_logo {
  opacity: 0;
}
header::after {
  height: 1px;
  background-color: #FFF;
  border-top: 1px solid #3f2e00;
  width: 100%;
  display: block;
  content: "";
}
header .navbar {
  height: 100%;
}
header .navbar #the_logo {
  width: 5.8rem;
  height: 5.8rem;
  margin-left: 1rem;
  margin-top: 0.3rem;
}
header .navbar #the_logo .logo-svg {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  transform: scale(0) rotate(0deg);
  opacity: 0;
}
header .navbar #the_logo .logo-svg svg {
  max-width: 100%;
  max-height: 100%;
}
header .navbar #the_logo .logo-svg.go {
  transform: scale(1) rotate(360deg);
  animation: spinIn 2s ease-out;
  opacity: 1;
}
header .navbar__menu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
header .navbar__menu li {
  margin-right: 1rem;
  font-size: 1.2rem;
}
header .navbar__menu li a {
  color: #000000;
  text-decoration: none;
  font-weight: 400;
  font-family: "Bungee", sans-serif;
  transition: color, 0.3s ease-in-out;
}
header .navbar__menu li:hover a, header .navbar__menu li.active a {
  color: #FFF;
}
header .navbar__toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
  padding: 8px;
  background: var(--heading-color, #E9C476);
  border-radius: 30%;
}
header .navbar__toggle span {
  height: 3px;
  width: 25px;
  background-color: #000;
  margin: 4px 0;
  transition: 0.4s;
}
header h4 {
  display: none;
}
@media (max-width: 768px) {
  header {
    height: 4rem;
  }
  header h4 {
    display: block;
    font-weight: 400;
    font-family: "Bungee", sans-serif;
    font-size: 1.5rem;
    margin: 0;
    color: #000;
    text-align: center;
    padding-top: 15px;
  }
  header .navbar__menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background-color: var(--heading-color, #E9C476);
    transition: left 0.3s ease;
    z-index: 1000;
  }
  header .navbar__menu li {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
  header .navbar__menu #the_logo {
    width: 200px;
    height: 200px;
    margin-bottom: 1.5rem;
    margin-left: 0;
  }
  header .navbar__menu.active {
    left: 0;
  }
  header .navbar__toggle {
    display: flex;
    position: fixed;
    top: 0.5rem;
    left: 1rem;
    transition: left 0.3s ease;
    z-index: 1001;
  }
  header .navbar__toggle.active {
    left: auto;
    right: 1rem;
  }
  header .navbar__toggle.active span:nth-of-type(2) {
    display: none;
  }
  header .navbar__toggle.active span:nth-of-type(1) {
    transform: translateY(10px) rotate(-45deg);
  }
  header .navbar__toggle.active span:nth-of-type(3) {
    transform: rotate(45deg);
  }
}`;

const shadowOptions = {"mode":"open"};
import { RWSViewComponent, RWSView, observable, attr } from '@rws-framework/client';
import { MenuEntry } from '../../types/MenuEntry';

@RWSView('website-header', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class Header extends RWSViewComponent  {  
    @attr menu: string;        
    @observable suluMenu: MenuEntry[] = [];
    @attr pageType: string = 'homepage';
    @observable currentUrl: string = window.location.pathname;

    @observable active: boolean = false;
    @observable go: boolean = false;
    @observable isOn: boolean = false;
    
    static fileList: string[] = [
        'assets/svg/logo.svg'
    ];
    
    connectedCallback(): void 
    {
        super.connectedCallback();
        
        if (this.menu) {
            try {                
                this.suluMenu = JSON.parse(this.menu);          
            } catch (e) {
                console.error('Invalid menu JSON:', e);
            }
        }      

        this.go = true;      
        console.log('OPENING');
    }

    disconnectedCallback(): void 
    {
        super.disconnectedCallback();

        console.log('OUTTA HERE!');
        this.go = false;
    }

    menuToggle(): void {
        this.active = !this.active;
    }

    clickMenu(item: MenuEntry, event: Event)
    {
        event.preventDefault();

        this.$emit('page:url:change', item.url);
        this.active = false;
    }

    goHome(event: Event)
    {
        event.preventDefault();

        this.$emit('page:url:change', '/')        
        this.active = false;
    }

    menuChanged(oldval: string, newval:string): void {
        if (newval.length) {
            try {
                this.suluMenu = JSON.parse(newval);                
            } catch (e) {
                console.error('Invalid top menu JSON:', e);
            }
        }
    }
}

Header.defineComponent();

export { Header };
